import React, { useState, useEffect } from 'react';
import '../Styles/ProductItem.css';
import dummyReviews from '../Data/dummyReviews';

function ProductItem({ product, index }) {
  const [expanded, setExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const amazonDomain = 'amazon.com';

  useEffect(() => {
    if (expanded) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 500); // Tempo deve coincidir com a duração da transição CSS
      return () => clearTimeout(timer);
    }
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderStars = (rating) => {
    const fullStars = 3;
    let lastTwoStars = rating - 8;

    if (lastTwoStars < 0) lastTwoStars = 0;

    const fullLastTwoStars = Math.floor(lastTwoStars);
    const partialLastTwoStar = lastTwoStars % 1 !== 0;

    return (
      <div className="stars">
        {[...Array(fullStars)].map((_, i) => (
          <i key={`full-${i}`} className="fas fa-star" />
        ))}
        {fullLastTwoStars >= 1 ? (
          <i key="full-4" className="fas fa-star" />
        ) : partialLastTwoStar ? (
          <i key="half-4" className="fas fa-star-half-alt" />
        ) : (
          <i key="empty-4" className="far fa-star" />
        )}
        {fullLastTwoStars === 2 ? (
          <i key="full-5" className="fas fa-star" />
        ) : fullLastTwoStars === 1 && partialLastTwoStar ? (
          <i key="half-5" className="fas fa-star-half-alt" />
        ) : (
          <i key="empty-5" className="far fa-star" />
        )}
      </div>
    );
  };

  const getRatingText = (rating) => {
    if (rating >= 9) {
      return 'Exceptional';
    } else if (rating >= 7) {
      return 'Very Good';
    } else {
      return 'Good';
    }
  };

  const reviews = dummyReviews.find(review => review.productId === product.id)?.reviews || [];
  const totalRatings = product.totalRatings;

  return (
    <div className="product-item">
      {product.badge && <div className={`product-badge ${product.badge.toLowerCase().replace(' ', '-')}`}>{product.badge}</div>}
      {product.value && <div className="product-value">{product.value}</div>}
      <div className="product-rank">{index < 10 ? `0${index}` : index}</div>
      <div className="product-image">
        <img src={product.imageUrl} alt={product.title} />
        <p>{product.title.split(' ')[0]}</p>
      </div>
      <div className="product-details">
        <h2>{product.title}</h2>
        <a href="#shipping" className="free-shipping">Free Shipping</a>
        <p><strong>Main highlights</strong></p>
        <p>{product.description}</p>
        <a href="#more" className="show-more" onClick={toggleExpanded}>
          {expanded ? 'Show less' : 'Show more'}
        </a>
        <div className={`detailed-description ${expanded ? 'expanded' : ''}`}>
          <ul>
            {product.details.map((detail, idx) => (
              <li key={idx}>{detail}</li>
            ))}
          </ul>
          {isVisible && (
            <div className={`reviews ${expanded ? 'expanded' : ''}`}>
              <h3>User Reviews <span className="total-ratings">{totalRatings.toLocaleString()} ratings</span></h3>
              <ul>
                {reviews.map((review, idx) => (
                  <li key={idx}>
                    <p><strong>{review.author}</strong> - {review.rating} stars</p>
                    <p>{review.comment}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="product-rating">
        <div className="rating-value">{product.rating}</div>
        <div className="rating-text">{getRatingText(product.rating)}</div>
        {renderStars(product.rating)}
        <a href={product.amazonUrl} className="view-deal" target="_blank" rel="noopener noreferrer">
          View Price
        </a>
        <p>{amazonDomain}</p>
      </div>
    </div>
  );
}

export default ProductItem;
