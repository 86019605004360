// src/Components/Footer.js
import React from 'react';
import '../Styles/Footer.css';
import logo from '../assets/logo.png';  

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <nav className="footer-nav">
          <a href="#contact">Contact</a>
          <a href="/about">About Us</a>
        </nav>
        <div className="footer-logo">
          <a href="/" className="footer-logo-link">
            <img src={logo} alt="Tech Best Review Logo" />
            <span>Tech Best Review</span>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
