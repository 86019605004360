import React from 'react';
import ProductItem from './ProductItem';
import '../Styles/ProductList.css';

const products = [
  {
    id: 1,
    title: 'Technics EAH-AZ80',
    rating: 9.9,
    description: 'Technics Premium Hi-Fi True Wireless Bluetooth Earbuds with Advanced Noise Cancelling, 3 Device Multipoint Connectivity, Wireless Charging, Hi-Res Audio + Enhanced Calling',
    imageUrl: 'https://m.media-amazon.com/images/I/61Da1EgT1yL._AC_SL1500_.jpg',
    amazonUrl: 'https://amzn.to/4fr1C0T',
    badge: 'Best Choice',
    totalRatings: 9570,
    details: [
      'An Epic Experience of Sound',
      'Stay Clear, Connected, and In Control',
      'Outstanding Call Clarity',
      'Elegantly Wireless',
      'Advanced Ergonomics',
    ]
  },
  {
    id: 2,
    title: 'Nothing Ear',
    rating: 9.8,
    description: 'Wireless Earbuds with ChatGPT Integration, 45dB Hybrid Noise Cancelling Earbuds, Hi-Res Audio, Advanced Equaliser, Dual Connect, 6 Mics, 42.5H Playtime.',
    imageUrl: 'https://m.media-amazon.com/images/I/51kK7ZFW4tL._AC_SL1000_.jpg',
    amazonUrl: 'https://amzn.to/3Wn4J24',
    totalRatings: 9150, 
    details: [
      'Powerful 11 mm driver',
      'Smart Active Noise Cancellation',
      'Clear Voice Technology',
      'Long Playtime, Fast Charging',
      'ChatGPT, Integrated',
      'More Performance'
    ]
  },
  {
    id: 3,
    title: 'Bose QuietComfort Earbuds 2',
    rating: 9.7,
    description: 'Wireless, Bluetooth, Proprietary Active Noise Cancelling Technology In-Ear Headphones with Personalized Noise Cancellation & Sound, Triple Black.',
    imageUrl: 'https://m.media-amazon.com/images/I/51KW4y31pJL._AC_SL1200_.jpg',
    amazonUrl: 'https://amzn.to/3XZyh7i',
    totalRatings: 4500,
    details: [
      'Quietcomfort earbuds 2',
      'Bose proprietary active noise cancelling technology earbuds',
      'Bose comfortability',
      'Wireless & easy to use',
      'Long lasting battery',
      'Advanced bluetooth technology',
      'Bose app'
    ]
  },
  {
    id: 4,
    title: 'EarFun Air Pro 3',
    rating: 9.6,
    description: 'EarFun Air Pro 3 Noise Cancelling Wireless Earbuds, Qualcomm® aptX™ Adaptive Sound, 6 Mics cVc 8.0 ENC, Bluetooth 5.3 Earbuds, Multipoint Connection, 45H Playtime, App Customize EQ, Wireless Charging.',
    imageUrl: 'https://m.media-amazon.com/images/I/61dDIKxp6sL._AC_SL1500_.jpg',
    amazonUrl: 'https://amzn.to/4cNrRgc',
    value: 'Best Value',
    totalRatings: 8700, 
    details: [
      'Latest Qualcomm QCC3071 SoC & aptX Adaptive Audio',
      'Qualcomm cVc 8.0 ENC with 6 Mics',
      'Reduces Noise Up to 43dB',
      'Seamless Multipoint Connection & Bluetooth 5.3',
      '45 Hrs of Total Playtime',
      'Custom EarFun Audio App'
    ]
  },
  {
    id: 5,
    title: 'Apple AirPods Pro 2',
    rating: 9.4,
    description: 'Wireless Ear Buds with USB-C Charging, Up to 2X More Active Noise Cancelling Bluetooth Headphones, Transparency Mode, Adaptive Audio, Personalized Spatial Audio.',
    imageUrl: 'https://m.media-amazon.com/images/I/61SUj2aKoEL._AC_SL1500_.jpg',
    amazonUrl: 'https://amzn.to/4cBBXAy', 
    totalRatings: 13400, 
    details: [
      'Richer audio experience',
      'Next-level active noise cancellation',
      'Customizable fit',
      'Dust, sweat, and water resistant',
      'Personalized spatial audio',
      'A higher level of control',
      'Long battery life'
    ]
  },
  {
    id: 6,
    title: 'Samsung Galaxy Buds 2 Pro',
    rating: 9.3,
    description: 'SAMSUNG Galaxy Buds 2 Pro True Wireless Bluetooth Earbuds, Noise Cancelling, Hi-Fi Sound, 360 Audio, Comfort Fit, HD Voice, IPX7 Water Resistant, Graphite.',
    imageUrl: 'https://m.media-amazon.com/images/I/61Qqg+T8nsL._AC_SL1500_.jpg',
    amazonUrl: 'https://amzn.to/3Wbu7Xr',
    totalRatings: 11900, 
    details: [
      'Active noise cancellation',
      'Hi-fi sound quality',
      'Enhanced 360-degree audio',
      'Excellent fit',
      'Hd voice technology',
      'Intelligent conversation mode',
      'Water resistant'
    ]
  },
  {
    id: 7,
    title: 'JBL Live Beam 3',
    rating: 9.2,
    description: 'True wireless noise-cancelling closed-stick earbuds, 48Hrs total playback, Wireless Charging, 6 Mics for perfect calls, Multi-point connection, IP55 waterproof and dustproof.',
    imageUrl: 'https://m.media-amazon.com/images/I/61pOEI+AnDL._AC_SL1500_.jpg',
    amazonUrl: 'https://amzn.to/3Lnv9L1',
    totalRatings: 10500, // Adicionado o totalRatings
    details: [
      'Convenient Smart Charging Case',
      'True adaptive noise cancelling with custom control',
      '6 mics for perfect calls',
      'Multi-point connection',
      'IP55 waterproof and dustproof',
      'Hi-Res Audio Wireless with JBL signature sound'
    ]
  },  
  {
    id: 8,
    title: 'Sony WF-1000XM5',
    rating: 9.1,
    description: 'Sony WF-1000XM5 The Best Truly Wireless Bluetooth Noise Canceling Earbuds Headphones with Alexa Built in.',
    imageUrl: 'https://m.media-amazon.com/images/I/61MgPeUAfvL._AC_SL1500_.jpg',
    amazonUrl: 'https://amzn.to/3Yd3ZxX',
    totalRatings: 9700, // Adicionado o totalRatings
    details: [
      'The best noise canceling earbuds',
      'Astonishing sound quality',
      'Sony’s best ever call quality',
      'Small & beautifully designed',
      'Clear, stable bluetooth signal',
      'Smart features',
      'Speak-to-chat'
    ]
  },
  {
    id: 9,
    title: 'Google Pixel Buds A-Series',
    rating: 9.0,
    description: 'Custom-designed 12 mm dynamic speaker drivers, Google Assistant, and up to 24 hours of battery life.',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwg5moBbAjRSa7HdPnw4arV4Bo2FTH9OJZ1g&s',
    amazonUrl: 'https://amzn.to/3y9KbAV',
    totalRatings: 8300, // Adicionado o totalRatings
    details: [
      'Custom-designed 12 mm dynamic speaker drivers',
      'Google Assistant built-in',
      'Up to 24 hours of battery life',
      'Adaptive Sound adjusts the volume as you move between quiet and noisy environments',
      'Sweat and water resistant (IPX4)',
      'Comfortable and secure fit'
    ]
  },
  {
    id: 10,
    title: 'Beats Fit Pro',
    rating: 8.9,
    description: 'Beats Fit Pro - True Wireless Noise Cancelling Earbuds - Apple H1 Headphone Chip, Compatible with Apple & Android, Class 1 Bluetooth, Built-in Microphone, 6 Hours of Listening Time.',
    imageUrl: 'https://m.media-amazon.com/images/I/5118KKFLhaL._AC_SL1500_.jpg',
    amazonUrl: 'https://amzn.to/46kDYyA',
    totalRatings: 9200, // Adicionado o totalRatings
    details: [
      'Flexible, secure-fit wingtips for all-day',
      'Custom acoustic platform delivers powerful',
      'Spatial Audio with dynamic head tracking for immersive music',
      'Three distinct listening modes: Active Noise Cancelling',
      'Sweat-resistant (IPX4)',
      'Up to 6 hours of listening time'
    ]
  }
];

function ProductList() {
  return (
    <div className="product-list">
      <h1 className="product-list-title">The Best Earbuds 2024</h1>
      {products.map((product, index) => (
        <ProductItem key={product.id} product={product} index={index + 1} />
      ))}
    </div>
  );
}
export default ProductList;
