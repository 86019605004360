// src/Data/dummyReviews.js

const dummyReviews = [
  {
    productId: 1,
    reviews: [
      {
        author: 'John Doe',
        rating: 5,
        comment: 'The noise cancellation is superb, and the sound quality is exceptional. Highly recommend these!',
      },
      {
        author: 'Jane Smith',
        rating: 4,
        comment: 'Great earbuds, but the price is a bit high. They fit well and are very comfortable.',
      },
      {
        author: 'Alice Johnson',
        rating: 4.5,
        comment: 'Worth the investment. Battery life is excellent, and the spatial audio is immersive.',
      },
    ],
  },
  {
    productId: 2,
    reviews: [
      {
        author: 'Michael Brown',
        rating: 4.5,
        comment: 'Fantastic sound quality and the active noise cancellation works like a charm.',
      },
      {
        author: 'Emily Davis',
        rating: 4.2,
        comment: 'Comfortable to wear for long periods. The touch controls are a bit sensitive, though.',
      },
      {
        author: 'Chris Wilson',
        rating: 4.8,
        comment: 'Excellent earbuds for the price. The only downside is the slightly short battery life.',
      },
    ],
  },
  {
    productId: 3,
    reviews: [
      {
        author: 'Sarah Thompson',
        rating: 4.9,
        comment: 'These are the best earbuds I have ever used. The noise cancellation is unparalleled.',
      },
      {
        author: 'David Martinez',
        rating: 4.7,
        comment: 'The sound quality is top-notch. A bit expensive, but worth every penny.',
      },
      {
        author: 'Laura Lee',
        rating: 4.6,
        comment: 'Very comfortable and the app integration is seamless. Battery life could be better.',
      },
    ],
  },
  {
    productId: 4,
    reviews: [
      {
        author: 'Robert White',
        rating: 4.8,
        comment: 'Great fit and sound quality. Perfect for workouts and daily use.',
      },
      {
        author: 'Jessica Harris',
        rating: 4.5,
        comment: 'Durable and comfortable. The sound is clear and powerful.',
      },
      {
        author: 'Brian Clark',
        rating: 4.9,
        comment: 'Amazing noise cancellation and the fit is very secure. Highly recommended.',
      },
    ],
  },
  {
    productId: 5,
    reviews: [
      {
        author: 'Karen Lewis',
        rating: 4.6,
        comment: 'Good value for the money. The sound quality is fantastic and they are very comfortable.',
      },
      {
        author: 'Matthew Young',
        rating: 4.4,
        comment: 'Great sound and the ANC works well. The case could be a bit more compact.',
      },
      {
        author: 'Patricia King',
        rating: 4.7,
        comment: 'Battery life is decent, and the sound quality is excellent. Would buy again.',
      },
    ],
  },
  {
    productId: 6,
    reviews: [
      {
        author: 'Daniel Adams',
        rating: 4.7,
        comment: 'Flexible fit and great sound quality. The noise cancelling feature is very effective.',
      },
      {
        author: 'Samantha Green',
        rating: 4.5,
        comment: 'Love the spatial audio. These are perfect for my daily commute.',
      },
      {
        author: 'Ethan Brooks',
        rating: 4.8,
        comment: 'Excellent earbuds for the price. Comfortable and great sound.',
      },
    ],
  },
  {
    productId: 7,
    reviews: [
      {
        author: 'Olivia Brown',
        rating: 4.6,
        comment: 'The targeted noise cancelling is impressive. The earbuds are comfortable and sound great.',
      },
      {
        author: 'James Taylor',
        rating: 4.4,
        comment: 'Good battery life and clear sound. They fit well and are easy to use.',
      },
      {
        author: 'Charlotte Wilson',
        rating: 4.7,
        comment: 'Great for everyday use. The app provides useful customization options.',
      },
    ],
  },
  {
    productId: 8,
    reviews: [
      {
        author: 'Liam Scott',
        rating: 4.8,
        comment: 'Superior sound quality with deep bass. The noise cancellation is very effective.',
      },
      {
        author: 'Emma Johnson',
        rating: 4.7,
        comment: 'Comfortable and the battery life is excellent. Worth the investment.',
      },
      {
        author: 'Mason Williams',
        rating: 4.9,
        comment: 'Best earbuds I have used so far. The sound is crisp and clear.',
      },
    ],
  },
  {
    productId: 9,
    reviews: [
      {
        author: 'Sophia Brown',
        rating: 4.5,
        comment: 'Custom-designed drivers provide excellent sound. Google Assistant integration is seamless.',
      },
      {
        author: 'Lucas Miller',
        rating: 4.3,
        comment: 'Good battery life and clear sound. The fit could be better.',
      },
      {
        author: 'Amelia Davis',
        rating: 4.6,
        comment: 'Great for everyday use. The sound quality is very good.',
      },
    ],
  },
  {
    productId: 10,
    reviews: [
      {
        author: 'Isabella Garcia',
        rating: 4.4,
        comment: 'Good noise cancellation and sound quality. Alexa integration is a plus.',
      },
      {
        author: 'Ethan Martinez',
        rating: 4.5,
        comment: 'Great value for the price. The fit is comfortable and secure.',
      },
      {
        author: 'Ava Hernandez',
        rating: 4.6,
        comment: 'Decent battery life and very good sound. Would recommend to others.',
      },
    ],
  },
];

export default dummyReviews;
