// src/Components/Header.js
import React, { useEffect, useState } from 'react';
import '../Styles/Header.css';
import logo from '../assets/logo.png';  
import usFlag from '../assets/us_flag.png';  
import canadaFlag from '../assets/canada_flag.png';  

function Header() {

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <a href="/" className='logo-link'>
            <img src={logo} alt="Tech Best Review Logo" />
            <span>Tech Best Review</span>
          </a>
        </div>
        <nav className="header-nav">
          <span>Best Products USA</span>
          <div className="flag-icon">
            <img src={usFlag} alt="USA flag"/>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
