// src/Components/AboutUs.js
import React from 'react';
import '../Styles/AboutUs.css';
import logo from '../assets/logo.png';

function AboutUs() {
  return (
    <div className="about-us">
      <div className="about-us-container">
        <div className="about-us-header">
          <img src={logo} alt="Tech Best Review Logo" className="about-us-logo" />
          <h1>About Tech Best Review</h1>
        </div>
        <div className="about-us-content">
          <p>Welcome to Tech Best Review, your number one source for all things tech. We're dedicated to providing you the very best of product reviews, with an emphasis on accuracy, reliability, and comprehensiveness.</p>
          <p>Founded in [Year], Tech Best Review has come a long way from its beginnings. When we first started out, our passion for technology and innovation drove us to start this website so that Tech Best Review can offer you the most comprehensive tech product reviews available. We now serve customers all over the world and are thrilled that we're able to turn our passion into our own website.</p>
          <p>At Tech Best Review, we believe that technology is the cornerstone of modern living. It shapes the way we work, play, and connect with one another. That's why we strive to bring you detailed and unbiased reviews of the latest gadgets, software, and tech innovations. Our team of expert reviewers rigorously tests each product to ensure that we provide you with thorough and accurate information, helping you make informed decisions about the technology you invest in.</p>
          <p>Our mission is to demystify the world of technology. We know that the tech landscape can be overwhelming, with new products and updates being released constantly. Our goal is to cut through the noise and provide clear, concise, and honest reviews that highlight the pros and cons of each product. Whether you're a tech enthusiast or a casual user, our reviews are designed to be accessible and informative for everyone.</p>
          <p>In addition to our in-depth reviews, we also provide a range of other resources to help you stay up-to-date with the latest tech trends. Our blog features articles on a variety of tech-related topics, including tips and tricks, how-to guides, and industry news. We also offer comparison charts, buyer's guides, and video reviews to give you a comprehensive understanding of the products you're interested in.</p>
          <p>We believe in the power of community and encourage our readers to share their own experiences and opinions. Your feedback is invaluable to us and helps us improve our content and services. If you have any questions, comments, or suggestions, please don't hesitate to contact us. We're always here to help and would love to hear from you.</p>
          <p>Thank you for choosing Tech Best Review as your trusted source for tech reviews. We hope you enjoy our content and find it helpful in your tech journey. Together, let's explore the exciting world of technology and make the best choices for our tech needs.</p>
          <p>Sincerely,</p>
          <p>The Tech Best Review Team</p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
